// window._ = require('lodash');

try {
	window.$ = window.jQuery = require('jquery');
	window.Popper = require('@popperjs/core').createPopperLite;
	$(function () {
		$('[data-bs-toggle="popover"]').popover({
			// 		html: true,
			// 		content: function () {
			// 			this.update();
			// 			var content = $(this).attr("popover-content");
			// 			return content;
			// 		},
			// 		// title: function() {
			// 		// 	var title = $(this).attr("data-popover-content");
			// 		// 	return $(title).children(".popover-heading").html();
			// 		// }
		})
	})
	require('bootstrap');
	window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
} catch (e) {
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

// window.axios = require('axios');

// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
