var timesArray = [];
$('#menu ul li.has-sub').hover(
    function () {
        let id = $.data(this);
        let ul = $(this).children('ul');
        timesArray[id] = setTimeout(function () {
            ul.css('display', 'block');
            timesArray[id] = "";
        }, 250);
    },
    function () {
        let id = $.data(this);
        let ul = $(this).children('ul');
        ul.css('display', 'none');
        if (timesArray[id]) {
            clearTimeout(timesArray[id]);
        }
    }
);
